<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">Model overview</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <hr class="wide blue-line short" />
            <div class="grid-x pb-2">
              <div class="cell small-12 medium-2">
                <h4>Purpose</h4>
              </div>
              <div class="cell small-12 medium-10">
                <p class="mb-0">
                  Forecast the potential budget impact of Byooviz for the treatment of:
                </p>
                <ul>
                  <li>Neovascular (wet) age-related macular degeneration (wAMD)</li>
                  <li>Visual impairment due to diabetic macular oedema (DME)</li>
                  <li>Proliferative diabetic retinopathy (PDR)</li>
                  <li>
                    Visual impairment due to macular oedema secondary to retinal vein occlusion
                    (branch RVO or central RVO)
                  </li>
                  <li>Visual impairment due to choroidal neovascularisation (CNV)</li>
                </ul>
              </div>
            </div>
            <hr class="wide blue-line short" />
            <div class="grid-x pb-2">
              <div class="cell small-12 medium-2">
                <h4>Population</h4>
              </div>
              <div class="cell small-12 medium-10">
                <p class="mb-0">
                  The number of patients with any of the indications listed above who are eligible
                  for treatment.
                </p>
              </div>
            </div>
            <hr class="wide blue-line short" />
            <div class="grid-x pb-2">
              <div class="cell small-12 medium-2">
                <h4>Time Horizon</h4>
              </div>
              <div class="cell small-12 medium-10">
                <p class="mb-0">
                  1 to 3 years
                </p>
              </div>
            </div>
            <hr class="wide blue-line short" />
            <div class="grid-x pb-2">
              <div class="cell small-12 medium-2">
                <h4>Cost</h4>
              </div>
              <div class="cell small-12 medium-10">
                <p class="mb-0">
                  The model is a drug acquisition model only. Monitoring costs and costs associated
                  with inpatient or outpatient follow-up are not included. The costs associated
                  with any other drugs used to treat patients with these indications are not
                  included.
                </p>
              </div>
            </div>
            <hr class="wide blue-line short" />
            <div class="grid-x pb-2">
              <div class="cell small-12 medium-2">
                <h4>Key<br />Assumptions</h4>
              </div>
              <div class="cell small-12 medium-10">
                <ul>
                  <li>
                    New patients started on treatment are assumed to be on therapy for 6 months in
                    the first year. This is to allow for the reality that some patients will start
                    in month 1 and some in month 12.
                  </li>
                  <li>
                    All patients started or switched are assumed to receive treatment in
                    subsequent years.
                  </li>
                  <li>
                    Patient drop out is not included. Patients started on treatment or switched to
                    a different ranibizumab brand are assumed to stay on treatment for the
                    duration of the modelling period.
                  </li>
                  <li>
                    Dosing assumptions are based on the product's SmPC, although dosing frequency
                    may vary on a patient by patient basis based on clinical response to treatment.
                  </li>
                  <li>
                    Default prices included in the model are based on NHS list price. The model
                    makes provision to include discounts or local prices.
                  </li>
                </ul>
              </div>
            </div>
            <div class="grid-x">
              <div class="cell small-12 medium-1 medium-offset-11">
                <router-link to="/how-to-use">
                  <button class="button expanded">Next</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Start',
  components: {
    Header,
  },
};
</script>
